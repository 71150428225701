import * as firebase from 'firebase';
import {createBrowserHistory} from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Route } from 'react-router';
import { Router } from 'react-router-dom';
import App from './App';
import config from './lib/config';
import registerServiceWorker from './registerServiceWorker';

firebase.initializeApp(config);
const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Route path="/" component={App}/>
  </Router>,
  document.getElementById('root') as HTMLElement
);
registerServiceWorker();
