import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import * as React from 'react';
import { RouteComponentProps } from "react-router";
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader';
import StyledButton from '../../components/StyledButton/StyledButton';
import { Container, PageAction, PageActions, PageTitle } from '../../components/Styling/Styling';
import config from '../../lib/config';
import { Video } from "../../types";

interface Props extends RouteComponentProps {

}

interface State {
  loading: boolean;
  videos: Array<Video>;
}

class Videos extends React.Component<Props, State> {
  state = {
    loading: false,
    videos: [],
  };

  async componentDidMount() {
    try {
      this.setState({loading: true});
      const res = await axios.post(`${config.apiUrl}/getAllVideos`);
      this.setState({videos: res.data.Videos});
    } catch (err) {
      console.log('error', err);
    } finally {
      this.setState({loading: false});
    }
  }

  render() {
    const {videos, loading} = this.state;

    if (loading) {
      return <FullPageLoader/>;
    }

    return (
      <Container>
        <PageTitle>Videos</PageTitle>

        <PageActions>
          <PageAction>
            <StyledButton
              type="button"
              variant="raised"
              color="primary"
              link="/videos/add"
            >
              Add Video
            </StyledButton>
          </PageAction>
        </PageActions>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              videos.map((item: Video) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <StyledButton
                      type="button"
                      variant="raised"
                      color="primary"
                      link={`/videos/${item.id}`}
                    >
                      View
                    </StyledButton>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Container>
    );
  }
}

export default Videos;