import axios from 'axios';
import * as firebase from 'firebase';
import * as React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader';
import StyledButton from '../../components/StyledButton/StyledButton';
import { Container, PageTitle, } from '../../components/Styling/Styling';
import TextFields from '../../components/TextFields/TextFields';
import config from '../../lib/config';
import { Resource } from "../../types";

interface Props extends RouteComponentProps {

}

interface State {
  resource: Resource;
  isUploading: boolean;
  progress: number;
  loading: boolean;
}

const StyledFileUploader = styled(FileUploader)`
  padding: .5rem 1rem;
`;

class Resources extends React.Component<Props, State> {
  state = {
    resource: {
      id: '',
      name: '',
      description: '',
      type: '',
      pdf: '',
    },
    isUploading: false,
    progress: 0,
    loading: false,
  };

  handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = event.currentTarget;
    this.setState(prevState => ({...prevState, resource: {...prevState.resource, [name]: value}}));
  }

  handleUploadStart = () => {
    this.setState({isUploading: true});
  }

  handleProgress = (progress: number) => {
    this.setState({progress});
  }

  handleUploadError = (error: any) => {
    this.setState({isUploading: false});
    console.error(error);
  }

  handleUploadSuccess = async (filename: string) => {
    this.setState({isUploading: false});
    const pdf = await firebase.storage().ref('images').child(filename).getDownloadURL();
    this.setState(prevState => ({...prevState, resource: {...prevState.resource, pdf}}));
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      this.setState({loading: true});
      await axios.post(`${config.apiUrl}/addResource`, this.state.resource);
      this.props.history.push('/resources');
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({loading: false});
    }
  }

  render() {
    const {resource, isUploading, progress, loading} = this.state;

    if (loading) {
      return <FullPageLoader/>;
    }

    return (
      <Container>
        <PageTitle>Add Resource</PageTitle>

        <form onSubmit={this.handleSubmit}>
          <TextFields
            name="name"
            placeholder="Name"
            type="text"
            value={resource.name}
            onChange={this.handleOnChange}
            required={true}
          />
          <TextFields
            name="description"
            placeholder="Description"
            type="text"
            value={resource.description}
            onChange={this.handleOnChange}
            required={true}
          />
          <TextFields
            name="type"
            placeholder="Type"
            type="number"
            value={resource.type}
            onChange={this.handleOnChange}
            required={true}
          />
          <StyledButton type="upload" variant="raised" color="upload">
            <StyledFileUploader
              accept="image/*,application/pdf"
              name="image"
              randomizeFilename={true}
              storageRef={firebase.storage().ref('images')}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
            />
          </StyledButton>
          {isUploading && <p>Progress: {progress}%</p>}
          {resource.pdf && <p><a href={resource.pdf} target="_blank">View</a></p>}
          <StyledButton
            type="submit"
            variant="raised"
            color="primary"
            disabled={isUploading}
          >
            Submit
          </StyledButton>
        </form>
      </Container>
    );
  }
}

export default Resources;