import styled from 'styled-components';
import StyledButton from '../../components/StyledButton/StyledButton';

export const Container = styled.div`
  margin: 0 auto;
  display: block;
  max-width: 1500px;
  padding: 0 15px;
`;

export const PageTitle = styled.h1`
  font-size: 2em;
  padding-bottom: 1.5rem;
`;

export const TableTitle = styled.h2`
  font-size: 1.5em;
  padding-top: 1rem;
  padding-bottom: 0;
`;

export const PageActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PageAction = styled.div`
  padding-left: 1rem;
`;

export const SubmitButton = styled(StyledButton)`
  display: block;
  margin-top: 2rem;
`;

export const FormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddFile = styled.div`
  display: flex;
  align-items:center;
  justify-content: flex-start;
  
  button {
    margin-left: 1rem;
  }
`;