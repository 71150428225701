import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import * as firebase from 'firebase';
import * as React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader';
import StyledButton from '../../components/StyledButton/StyledButton';
import { AddFile, Container, FormButtons, PageTitle, TableTitle } from '../../components/Styling/Styling';
import TextFields from '../../components/TextFields/TextFields';
import config from '../../lib/config';

interface Props extends RouteComponentProps<{ videoId: string }> {

}

interface State {
  name: string;
  newFileName: string;
  newFile: string;
  files: any;
  loading: boolean;
  isUploading: boolean;
  progress: number;
}

const StyledFileUploader = styled(FileUploader)`
  padding: .5rem 1rem;
`;

class EditVideo extends React.Component<Props, State> {
  state = {
    name: '',
    newFileName: '',
    newFile: '',
    files: [],
    loading: false,
    isUploading: false,
    progress: 0,
  };

  async componentDidMount() {
    try {
      this.setState({loading: true});
      const postData = {
        video_id: this.props.match.params.videoId,
      };
      const data = await axios.post(`${config.apiUrl}/getVideo`, postData);
      this.setState({
        name: data.data.Video.name,
        files: data.data.Video.files,
      });
      this.props.history.push('/videos');
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({loading: false});
    }
  }

  handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = event.currentTarget;
    this.setState(prevState => ({...prevState, [name]: value}));
  }

  handleUploadStart = () => {
    this.setState({
      isUploading: true,
      progress: 0,
    });
  }

  handleProgress = (progress: number) => {
    this.setState({progress});
  }

  handleUploadError = (error: any) => {
    this.setState({isUploading: false});
    console.error(error);
  }

  handleUploadSuccess = async (filename: string) => {
    this.setState({isUploading: false});
    const newFile = await firebase.storage().ref('images').child(filename).getDownloadURL();
    this.setState(prevState => ({...prevState, isUploading: false, newFile}));
  }

  handleRemoveFile = async (index: number) => {
    try {
      this.setState(
        (prev: any) => {
          return {
            ...prev,
            files: this.state.files.filter((_, i) => i !== index)
          };
        });
    } catch (e) {
      console.log('error', e);
      this.setState({loading: false});
    }
  }

  addFile = async () => {
    const {newFileName, newFile} = this.state;
    if (newFileName && newFile) {
      this.setState(
        () => {
          return {
            files: [...this.state.files, {
              name: newFileName,
              link: newFile
            }]
          };
        },
        () => {
          this.setState({
            newFileName: '',
            newFile: ''
          });
        });
    }
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      this.setState({loading: true});
      const postData = {
        video_id: this.props.match.params.videoId,
        video_files: this.state.files,
        ...this.state
      };
      await axios.post(`${config.apiUrl}/editVideo`, postData);
      this.props.history.push('/videos');
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({loading: false});
    }
  }

  handleDelete = async () => {
    try {
      this.setState({loading: true});
      const postData = {
        video_id: this.props.match.params.videoId,
      };
      await axios.post(`${config.apiUrl}/deleteVideo`, postData);
      this.props.history.push('/videos');
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({loading: false});
    }
  }

  render() {
    const {
      name,
      newFileName,
      newFile,
      files,
      isUploading,
      loading,
    } = this.state;

    if (loading) {
      return <FullPageLoader/>;
    }

    return (
      <Container>
        <PageTitle>Edit Video</PageTitle>

        <form onSubmit={this.handleSubmit}>
          <TextFields
            name="name"
            placeholder="Name"
            type="text"
            value={name}
            onChange={this.handleOnChange}
            required={true}
          />

          <TableTitle>Video List</TableTitle>
          {
            !files.length
              ?
              <div>
                <p>There are no files for this video</p>
              </div>
              :
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Url</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    files.map((dataItem: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {dataItem.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {dataItem.link}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <StyledButton
                            type="button"
                            variant="raised"
                            color="secondary"
                            onClick={() => this.handleRemoveFile(index)}
                          >
                            Remove
                          </StyledButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
          }
          <TableTitle>Add a File</TableTitle>
          <AddFile>
            <TextFields
              name="newFileName"
              placeholder="Name"
              type="text"
              value={newFileName}
              onChange={this.handleOnChange}
            />
            <StyledButton type="upload" variant="raised" color="upload">
              <StyledFileUploader
                accept="image/*,video/*"
                name="image"
                randomizeFilename={true}
                storageRef={firebase.storage().ref('images')}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </StyledButton>
          </AddFile>

          <StyledButton
            type="button"
            variant="raised"
            color={(newFileName && newFile) ? 'primary' : 'default'}
            disabled={!newFileName || !newFile}
            onClick={this.addFile}
          >
            Add
          </StyledButton>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <FormButtons>
            <StyledButton
              type="submit"
              variant="raised"
              color="primary"
              disabled={isUploading}
            >
              Submit
            </StyledButton>
            <StyledButton
              type="button"
              variant="raised"
              color="secondary"
              disabled={isUploading}
              onClick={this.handleDelete}
            >
              Delete
            </StyledButton>
          </FormButtons>
        </form>
      </Container>
    );
  }
}

export default EditVideo;