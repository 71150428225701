import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from "react-router";
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader';
import StyledButton from '../../components/StyledButton/StyledButton';
import { Container, PageAction, PageActions, PageTitle } from '../../components/Styling/Styling';
import config from '../../lib/config';
import { Message } from "../../types";

interface Props extends RouteComponentProps {
}

interface State {
  loading: boolean;
  messages: Array<Message>;
}

class Messages extends React.Component<Props, State> {
  state = {
    loading: false,
    messages: [],
  };

  async componentDidMount() {
    try {
      this.setState({loading: true});
      const res = await axios.get(`${config.apiUrl}/getAllMessages`);
      this.setState({messages: res.data.Messages});
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({loading: false});
    }
  }

  render() {
    const {messages, loading} = this.state;

    if (loading) {
      return <FullPageLoader/>;
    }

    return (
      <Container>
        <PageTitle>Messages</PageTitle>

        <PageActions>
          <PageAction>
            <StyledButton type="button" variant="raised" color="primary" link="/messages/send">
              Send a Message
            </StyledButton>
          </PageAction>
        </PageActions>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Message</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              messages.map((item: Message) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.message}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(item.created_at).format('MMM Do YYYY')}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Container>
    );
  }
}

export default Messages;