import Button from '@material-ui/core/Button';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TabletV } from '../Breakpoints/Breakpoints';

interface Props {
  className?: string;
  children?: React.ReactChild;
  disabled?: boolean;
  type?: string;
  primary?: boolean;
  invertedPrimary?: boolean;
  green?: boolean;
  invertedGreen?: boolean;
  textTransform?: string;
  small?: boolean;
  variant?: any;
  color?: any;
  link?: string;

  onClick?(): void;
}

const StyledLink = styled(Link)`
  text-decoration: none;
`;

function BaseButton(props: Props) {
  const {className, children, type, onClick, disabled, variant, color, link} = props;

  if (link) {
    return (
      <StyledLink to={link}>
        <Button className={className} type={type} onClick={onClick} disabled={disabled} variant={variant} color={color}>
          {children}
        </Button>
      </StyledLink>
    );
  }

  return (
    <Button className={className} type={type} onClick={onClick} disabled={disabled} variant={variant} color={color}>
      {children}
    </Button>
  );
}

const StyledButton = styled(BaseButton)`
  font-size: 1em;
  border: 1px solid;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.625em;
  max-width: 15.125rem;
  line-height: inherit;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  
  ${ props => {
  if (props.small) {
    return 'max-width: 10rem;';
  }
  return '';
}};
  
   ${ props => {
  if (props.type === 'submit') {
    return 'display: block !important;margin-top: 1.5rem !important;';
  }
  return '';
}};
  
   ${ props => {
  if (props.type === 'upload') {
    return 'padding: 0 !important;max-width: fit-content !important;';
  }
  return '';
}};
  
  
  @media(${TabletV}) {
    font-size: 1.1875em;
    padding: 1.25rem 2.5em;
    max-width: 14rem;
  }
  
  ${ props => {
  if (props.primary) {
    return 'color: #fff; background-color: #334d2b; border: 1px solid #334d2b;';
  } else if (props.invertedPrimary) {
    return 'color: #334d2b; background-color: #fff; border: 1px solid #334d2b;';
  } else if (props.green) {
    return 'color: #fff; background-color: #659254; border: 1px solid #659254;';
  } else if (props.invertedGreen) {
    return 'color: #659254; background-color: #eef2ec; border: 1px solid #659254;';
  }
  return '';
}};
  
  ${ props => {
  if (props.textTransform === 'capitalize') {
    return 'text-transform: capitalize';
  }
  return '';
}};
  
  &:hover {
    ${ props => {
  if (props.primary) {
    return 'color: #fff; background-color: #1c3115; border: 1px solid #1c3115;';
  } else if (props.invertedPrimary) {
    return 'color: #334d2b; background-color: #92b784; border: 1px solid #92b784;';
  } else if (props.green) {
    return 'color: #fff; background-color: #334d2b; border: 1px solid #334d2b;';
  } else if (props.invertedGreen) {
    return 'color: #fff; background-color: #334d2b; border: 1px solid #659254;';
  }
  return '';
}};
  }
    
`;

export default StyledButton;