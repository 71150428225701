import * as firebase from 'firebase';
import moment from 'moment';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import StyledButton from '../../components/StyledButton/StyledButton';

interface Props {

}

interface State {
  loading: boolean;
  redirect: string;
}

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

const NavLeft = styled.div`
  display: flex;
`;

const NavRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  a {
    text-decoration: none;
  }
  
  > * {
      margin-right: 1rem;
      
      &:last-child {
        margin-right: 0;
      }
  }
`;

class Navbar extends React.Component<Props, State> {
  state = {
    loading: false,
    redirect: ''
  };

  async componentDidMount() {
    const tokenObject = localStorage.getItem('token');
    if (tokenObject) {
      const timestamp = JSON.parse(tokenObject).timestamp;
      let halfAnHourAgo = moment()
        .subtract(30, 'minutes')
        .toDate()
        .getTime();
      if (timestamp < halfAnHourAgo) {
        const currentUser = await firebase.auth().currentUser;
        if (currentUser) {
          const newToken = await currentUser.getIdToken(true);
          const object = {value: newToken, timestamp: new Date().getTime()};
          localStorage.setItem('token', JSON.stringify(object));
        }
      }
    } else {
      await firebase.auth().signOut();
      this.setState(({redirect: '/'}));
    }
  }

  logout = async () => {
    await firebase.auth().signOut();
    localStorage.removeItem('token');
    this.setState(({redirect: '/'}));
  }

  render() {
    const {
      redirect
    } = this.state;

    if (redirect) {
      return <Redirect to={redirect}/>;
    }
    return (
      <Nav>
        <NavLeft/>
        <NavRight>
          <StyledButton type="button" variant="raised" color="default" link="/resources">
            Resources
          </StyledButton>
          <StyledButton type="button" variant="raised" color="default" link="/videos">
            Videos
          </StyledButton>
          <StyledButton type="button" variant="raised" color="default" link="/messages">
            Messages
          </StyledButton>
          <StyledButton type="button" variant="raised" color="default" onClick={this.logout}>
            Logout
          </StyledButton>
        </NavRight>
      </Nav>
    );
  }
}

export default Navbar;