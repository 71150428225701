import * as firebase from 'firebase';
import * as React from 'react';
import { RouteComponentProps } from "react-router";
import styled from 'styled-components';
import StyledButton from '../../components/StyledButton/StyledButton';
import { Container, PageTitle, } from '../../components/Styling/Styling';
import TextFields from '../../components/TextFields/TextFields';

interface Props extends RouteComponentProps {
}

interface State {
  email: string;
  password: string;
}

const LoginContainer = styled.div`
    max-width: 500px;
    margin: 0 auto;
    display: block;
    position: absolute;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
`;

const StyledPageTitle = styled(PageTitle)`
  text-align: center;
  padding: 0;
`;

const StyledTextFields = styled(TextFields)`
  width: 100%;
`;

const LoginStyledButton = styled(StyledButton)`
    max-width: none;
    width: 100%;
    margin: 0 !important;
`;

class LoginPage extends React.Component<Props, State> {
  state = {
    email: '',
    password: '',
  };

  handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = event.currentTarget;
    this.setState(prevState => ({...prevState, [name]: value}));
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const {email, password} = this.state;
      await firebase.auth().signInWithEmailAndPassword(email, password);
      const currentUser = await firebase.auth().currentUser;
      if (currentUser) {
        const token = await currentUser.getIdToken();
        const object = {value: token, timestamp: new Date().getTime()};
        await localStorage.setItem('token', JSON.stringify(object));
      }
      this.props.history.push('/resources');
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const {email, password} = this.state;
    return (
      <Container>
        <LoginContainer>
          <StyledPageTitle>Login</StyledPageTitle>
          <form onSubmit={this.handleSubmit}>
            <StyledTextFields
              name="email"
              placeholder="Email"
              type="email"
              value={email}
              onChange={this.handleOnChange}
              required={true}
            />
            <TextFields
              name="password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={this.handleOnChange}
              required={true}
            />
            <LoginStyledButton type="submit" variant="raised" color="primary">
              Login
            </LoginStyledButton>
          </form>
        </LoginContainer>
      </Container>
    );
  }
}

export default LoginPage;