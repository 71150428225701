import * as React from 'react';
import { Route, Switch } from 'react-router';
import Navbar from "./components/Navbar/Navbar";
import AddResource from './routes/AddResource/AddResource';
import AddVideo from './routes/AddVideo/AddVideo';
import EditResource from './routes/EditResource/EditResource';
import EditVideo from './routes/EditVideo/EditVideo';
import LoginPage from './routes/LoginPage/LoginPage';
import Messages from './routes/Messages/Messages';
import Resources from './routes/Resources/Resources';
import SendMessage from './routes/SendMessage/SendMessage';
import Videos from './routes/Videos/Videos';

function App() {
  return (
    <main>
      <Switch>
        <Route exact={true} path="/" component={LoginPage}/>
        <Route
          path={["/resources", "/videos", "/messages"]}
          render={() => (
            <>
              <Navbar/>
              <Switch>
                <Route exact={true} path="/resources" component={Resources}/>
                <Route exact={true} path="/resources/add" component={AddResource}/>
                <Route exact={true} path="/resources/:resourceId" component={EditResource}/>

                <Route exact={true} path="/videos" component={Videos}/>
                <Route exact={true} path="/videos/add" component={AddVideo}/>
                <Route exact={true} path="/videos/:videoId" component={EditVideo}/>

                <Route exact={true} path="/messages" component={Messages}/>
                <Route exact={true} path="/messages/send" component={SendMessage}/>
              </Switch>
            </>
          )}
        />
      </Switch>
    </main>
  );
}

export default App;
