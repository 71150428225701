import axios from 'axios';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader';
import Navbar from '../../components/Navbar/Navbar';
import StyledButton from '../../components/StyledButton/StyledButton';
import { Container, PageTitle, } from '../../components/Styling/Styling';
import TextFields from '../../components/TextFields/TextFields';
import config from '../../lib/config';

interface Props extends RouteComponentProps {

}

interface State {
  message: string;
  loading: boolean;
}

class SendMessage extends React.Component<Props, State> {
  state = {
    message: '',
    loading: false,
  };

  handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = event.currentTarget;
    this.setState(prevState => ({...prevState, [name]: value}));
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      this.setState({loading: true});
      await axios.post(`${config.apiUrl}/sendMessage`, this.state);
      this.props.history.push('/messages');
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({loading: false});
    }
  }

  render() {
    const {message, loading} = this.state;

    if (loading) {
      return <FullPageLoader/>;
    }

    return (
      <>
        <Navbar/>
        <Container>
          <PageTitle>Send Message</PageTitle>

          <form onSubmit={this.handleSubmit}>
            <TextFields
              name="message"
              placeholder="Message"
              type="text"
              value={message}
              onChange={this.handleOnChange}
              required={true}
            />

            <StyledButton
              type="submit"
              variant="raised"
              color="primary"
            >
              Submit
            </StyledButton>
          </form>
        </Container>
      </>
    );
  }
}

export default SendMessage;