import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import styled from 'styled-components';
import { Desktop, Laptop, TabletH } from '../Breakpoints/Breakpoints';

interface Props {
  className?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  hint?: string;
  type: string;
  value?: string | number;
  removeAsterisk?: boolean;
  checked?: boolean;
  options?: any[];
  multiLine?: boolean;
  rows?: number;
  rowsMax?: number;

  onChange?(event: React.FormEvent<HTMLInputElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void;

  onSelect?(event: React.FormEvent<HTMLSelectElement>): void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  font-size: 1em;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.75rem;
`;

const StyledInput = styled(TextField)`
  font-size: 0.9375em;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 1rem .75rem;
  width: auto;
  color: #6b6b6b;
  font-family: 'Raleway', sans-serif;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;

  @media (${TabletH}) {
    font-size: 1em;
    padding: 1rem 0.9375rem;
  }

  @media (${Laptop}) {
    font-size: 1.125em;
    padding: 1.25rem 1rem;
  }
  
  &:required {
    box-shadow: none;
  }
  
  &.isStripe {
    font-size: 1.125em !important;
  }
`;

const Select = styled.select`
  font-size: 0.9375em;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  color: #6b6b6b;
  font-family: 'Raleway', sans-serif;
  box-shadow: none;
  height: 3.3125rem;
  background: #fff;
  text-indent: .25rem;

  @media (${TabletH}) {
    font-size: 1em;
    text-indent: 0.4375rem;
  }

  @media (${Laptop}) {
    font-size: 1.125em;
    height: 3.9375rem;
    text-indent: 0.5rem;
  }
`;

const StyledTextArea = styled(StyledInput)`
    height: 8em;

    @media (${Laptop}) {
        height: 10em;
    }

    @media (${Desktop}) {
        height: 11.5625em;
    }
`;

const Hint = styled.p`
  font-size: 1.4375em;
  color: #999999;
  margin-top: 1rem;
  margin-bottom: 0;
`;

class TextFields extends React.PureComponent<Props> {
  renderTextArea() {
    const {className, name, placeholder, value, required, rows, rowsMax, onChange} = this.props;
    return (
      <TextField
        className={className}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        rows={rows}
        rowsMax={rowsMax}
      />
    );
  }

  renderTextInput() {
    const {className, name, type, placeholder, value, required, onChange} = this.props;
    return (
      <TextField
        className={className}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        required={required}
      />
    );
  }

  renderSelect() {
    const {name, placeholder, value, required, onSelect, options} = this.props;
    if (options) {
      return (
        <Select onChange={onSelect} name={name} required={required} value={value ? value : ''}>
          <option key={0} value="">{placeholder}</option>
          {options.map((option: any, index: number) => (
            <option
              key={index + 1}
              value={option.code}
            >
              {option.name}
            </option>
          ))}
        </Select>
      );
    } else {
      return ('');
    }
  }

  renderHint() {
    const {hint} = this.props;
    return <Hint>{hint}</Hint>;
  }

  renderLabel() {
    const {label, required, removeAsterisk} = this.props;
    return (
      <Label>
        {label} {required && !removeAsterisk ? '*' : ''}
      </Label>
    );
  }

  render() {
    const {label, hint, type} = this.props;
    return (
      <Container>
        {!!label && this.renderLabel()}
        {type === 'textarea' ? this.renderTextArea() : ''}
        {type === 'select' ? this.renderSelect() : ''}
        {(type !== 'checkbox' && type !== 'textarea' && type !== 'select') && this.renderTextInput()}
        {!!hint && this.renderHint()}
      </Container>
    );
  }
}

export default TextFields;