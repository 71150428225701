import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import * as React from 'react';
import { RouteComponentProps } from "react-router";
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader';
import StyledButton from '../../components/StyledButton/StyledButton';
import { Container, PageAction, PageActions, PageTitle } from '../../components/Styling/Styling';
import config from '../../lib/config';
import { Resource } from "../../types";

interface Props extends RouteComponentProps {

}

interface State {
  loading: boolean;
  resources: Array<Resource>;
}

class Resources extends React.Component<Props, State> {
  state = {
    loading: false,
    resources: [],
  };

  async componentDidMount() {
    try {
      this.setState({loading: true});
      const res = await axios.get(`${config.apiUrl}/getAllResources`);
      this.setState({resources: res.data.Resources});
    } catch (e) {
      console.log('error', e);
    } finally {
      this.setState({loading: false});
    }
  }

  render() {
    const {resources, loading} = this.state;

    if (loading) {
      return <FullPageLoader/>;
    }

    return (
      <Container>
        <PageTitle>Resources</PageTitle>

        <PageActions>
          <PageAction>
            <StyledButton
              type="button"
              variant="raised"
              color="primary"
              link="/resources/add"
            >
              Add Resource
            </StyledButton>
          </PageAction>
        </PageActions>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              resources.map((item: Resource) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.type}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <StyledButton
                      type="button"
                      variant="raised"
                      color="primary"
                      link={`/resources/${item.id}`}
                    >
                      View
                    </StyledButton>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Container>
    );
  }
}

export default Resources;